import { RefObject, useEffect, useState } from 'react';

export const useOnScreen = (ref: RefObject<HTMLDivElement>, rootMargin = '0px') => {
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    const elem = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      },
    );
    if (elem) {
      observer.observe(elem);
    }
    return () => {
      if (elem) {
        observer.unobserve(elem);
      }
    };
  }, [ref, rootMargin]);
  return isIntersecting;
};
